import React, { useContext, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { SERVER_URL } from './constants'
import useTools from './useTools'
import { ConversationContext } from './ConversationContext'

const useRealtime = () => {
  const [events, setEvents] = useState([])
  const [dataChannel, setDataChannel]: any = useState(null)
  const peerConnection: any = useRef(null)
  const audioElement: any = useRef(null)
  const isFirstSession = useRef(true)
  const {
    currentConversationId,
    isSessionActive,
    setIsSessionActive,
    isConnecting,
    setIsConnecting,
  }: any = useContext(ConversationContext)
  useTools({ sendClientEvent, events })

  async function startSession() {
    setIsConnecting(true)
    // Get an ephemeral key from the Fastify server
    const tokenResponse = await fetch(
      `${SERVER_URL}/token?conversationId=${currentConversationId}`
    ) // send conversationId so server can populate message history
    const data = await tokenResponse.json()
    const EPHEMERAL_KEY = data.client_secret.value

    // Create a peer connection
    const pc = new RTCPeerConnection()

    // Set up to play remote audio from the model
    audioElement.current = document.createElement('audio')
    audioElement.current.autoplay = true
    pc.ontrack = (e) => (audioElement.current.srcObject = e.streams[0])

    // Add local audio track for microphone input in the browser
    const ms = await navigator.mediaDevices.getUserMedia({
      audio: true,
    })
    pc.addTrack(ms.getTracks()[0])

    // Set up data channel for sending and receiving events
    const dc: any = pc.createDataChannel('oai-events')
    setDataChannel(dc)

    // Start the session using the Session Description Protocol (SDP)
    const offer = await pc.createOffer()
    await pc.setLocalDescription(offer)

    const baseUrl = 'https://api.openai.com/v1/realtime'

    const sdpResponse = await fetch(`${baseUrl}`, {
      method: 'POST',
      body: offer.sdp,
      headers: {
        Authorization: `Bearer ${EPHEMERAL_KEY}`,
        'Content-Type': 'application/sdp',
      },
    })

    const answer: any = {
      type: 'answer',
      sdp: await sdpResponse.text(),
    }
    await pc.setRemoteDescription(answer)

    peerConnection.current = pc
  }

  // Stop current session, clean up peer connection and data channel
  function stopSession() {
    if (dataChannel) {
      dataChannel.close()
    }
    if (peerConnection.current) {
      peerConnection.current.close()
    }

    setIsSessionActive(false)
    setDataChannel(null)
    peerConnection.current = null
  }

  // Send a message to the model
  function sendClientEvent(message: any) {
    if (dataChannel) {
      message.event_id = message.event_id || crypto.randomUUID()
      dataChannel.send(JSON.stringify(message))
      setEvents((prev): any => [message, ...prev]) // is adding this client event really needed?
    } else {
      console.error(
        'Failed to send message - no data channel available',
        message
      )
    }
  }

  // Send a text message to the model
  function sendTextMessage(message: any) {
    const event = {
      type: 'conversation.item.create',
      item: {
        type: 'message',
        role: 'user',
        content: [
          {
            type: 'input_text',
            text: message,
          },
        ],
      },
    }

    sendClientEvent(event)
    sendClientEvent({ type: 'response.create' })
  }

  // Attach event listeners to the data channel when a new one is created
  useEffect(() => {
    if (dataChannel) {
      // Append new server events to the list, This includes when the user is talking, but not sendClientEvent
      dataChannel.addEventListener('message', (e: any) => {
        ReactDOM.flushSync(() => {
          // so it doesn't batch events
          setEvents((prev): any => [JSON.parse(e.data), ...prev])
        })
      })

      const text =
        `In less than 10 words, ` +
        (isFirstSession.current
          ? `introduce yourself and ask how you can help.`
          : `Using synonymous words, say a variation of something like  "How can I help?"`)

      // Set session active when the data channel is opened
      dataChannel.addEventListener('open', () => {
        setIsSessionActive(true)
        setEvents([])
        setIsConnecting(false)
        sendClientEvent({
          type: 'response.create',
          response: {
            // input is an alternative to instructions. instructions won't work here because it introduces itself as chat gpt
            input: [
              {
                type: 'message',
                role: 'user',
                content: [
                  {
                    type: 'input_text',
                    text,
                  },
                ],
              },
            ],
            temperature: 1.2, // anything below 0.6 it doesn't respond. 1.2 is the highest.
          },
        })
        isFirstSession.current = false
      })
    }
  }, [dataChannel])

  return {
    isSessionActive,
    events,
    startSession,
    stopSession,
    sendClientEvent,
    sendTextMessage,
    isConnecting,
  }
}

export default useRealtime
