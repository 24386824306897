import React, { createContext, useState, ReactNode } from 'react'

interface ConversationContextProps {
  currentConversationId: string
  setCurrentConversationId: React.Dispatch<React.SetStateAction<string>>
  allMessages: any
  setAllMessages: React.Dispatch<React.SetStateAction<any>>
  isSessionActive: boolean
  setIsSessionActive: React.Dispatch<React.SetStateAction<boolean>>
  isConnecting: boolean
  setIsConnecting: React.Dispatch<React.SetStateAction<boolean>>
}

const ConversationContext = createContext<ConversationContextProps | undefined>(
  undefined
)

const ConversationProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [currentConversationId, setCurrentConversationId] = useState<string>(
    Math.random().toString(36).substr(2, 9)
  )
  const [allMessages, setAllMessages] = useState<any>([])
  const [isSessionActive, setIsSessionActive] = useState(false)
  const [isConnecting, setIsConnecting] = useState(false)

  return (
    <ConversationContext.Provider
      value={{
        currentConversationId,
        setCurrentConversationId,
        allMessages,
        setAllMessages,
        isSessionActive,
        setIsSessionActive,
        isConnecting,
        setIsConnecting,
      }}
    >
      {children}
    </ConversationContext.Provider>
  )
}

export { ConversationContext, ConversationProvider }
