import React, { useEffect, useRef } from 'react'
import { fetchOptions } from './fetchOptions'
import { SERVER_URL } from './constants'

function useAudio() {
  const audioContextRef: any = useRef(null)
  const audioBufferSourceNodeRef: any = useRef(null)
  const audioBufferRef: any = useRef(null)
  
  useEffect(() => {
    async function fetchAudio() {
      const audioCtx = new (window.AudioContext ||
        (window as any).webkitAudioContext)()
      const response = await fetch(`${SERVER_URL}/audio`, {
        ...fetchOptions,
        method: 'GET',
        headers: {
          'Content-Type': 'audio/mpeg',
        },
      })
      const arrayBuffer = await response.arrayBuffer()
      const audioBuffer = await audioCtx.decodeAudioData(arrayBuffer)
      audioContextRef.current = audioCtx
      audioBufferRef.current = audioBuffer
    }

    fetchAudio()
  }, [])

  useEffect(() => {
    // Create an AudioContext on component mount
    audioContextRef.current = new AudioContext()

    return () => {
      // Clean up on component unmount
      if (audioContextRef.current) {
        audioContextRef.current.close()
      }
    }
  }, [])

  const playTypingAudio = async () => {
    stopTypingAudio()
    const audioCtx = audioContextRef.current
    if (!audioCtx) return

    // Create a new AudioBufferSourceNode
    const source = audioCtx.createBufferSource()
    source.buffer = audioBufferRef.current
    source.connect(audioCtx.destination)
    source.start(0)

    // Store the reference to stop it later
    audioBufferSourceNodeRef.current = source
  }

  const stopTypingAudio = () => {
    if (audioBufferSourceNodeRef.current) {
      audioBufferSourceNodeRef.current.stop()
      audioBufferSourceNodeRef.current = null
    }
  }
  return { playTypingAudio, stopTypingAudio }
}

export default useAudio
