import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ConversationProvider } from './ConversationContext'

// chat-popup has to be the same in index.html
const root = ReactDOM.createRoot(document.querySelector('.chat-popup') as HTMLElement)
root.render(
  // <React.StrictMode> // This causes double rendering
  <ConversationProvider>
    <App />
  </ConversationProvider>
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
