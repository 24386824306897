import React, { useContext } from 'react'
import {
  useState,
  useEffect,
  useRef,
  useCallback,
  useLayoutEffect,
} from 'react'
import {
  BiPhone,
  BiDownArrow,
  BiPlus,
  BiUser,
  BiSend,
  BiSolidUserCircle,
  BiMicrophone,
} from 'react-icons/bi'
import {
  MdOutlineArrowLeft,
  MdOutlineArrowRight,
  MdMinimize,
} from 'react-icons/md'
import { SERVER_URL } from './constants'
import useRealtime from './useRealtime'
import { fetchOptions } from './fetchOptions'
import { ConversationContext } from './ConversationContext'
import { scrollToBottom } from './utils'
import ConversationsList from './ConversationsList'
import brainPng from './static/brain.png'
import './App.css'

function App() {
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [text, setText] = useState<any>('')
  const [isResponseLoading, setIsResponseLoading] = useState<any>(false)
  const [errorText, setErrorText] = useState<any>('')
  const [isShowSidebar, setIsShowSidebar] = useState<any>(false)
  const scrollToLastItem = useRef<any>(null)
  const inputRef = useRef<any>(null)
  const [socketState, setSocket] = useState<any>(null)
  const [isScrolledToBottom, setIsScrolledToBottom] = useState<boolean>(true)
  const { isSessionActive, startSession, stopSession, isConnecting } =
    useRealtime()

  const {
    currentConversationId,
    setCurrentConversationId,
    allMessages,
    setAllMessages,
  }: any = useContext(ConversationContext)

  const currentMessages = allMessages.filter(
    (msg: any) => msg.conversationId === currentConversationId
  )
  const messagesToday = allMessages.filter(
    (msg: any) =>
      new Date(msg.created_at).toDateString() === new Date().toDateString()
  )
  const messagesNotToday = allMessages.filter(
    (msg: any) =>
      new Date(msg.created_at).toDateString() !== new Date().toDateString()
  )
  const conversationsToday = messagesToday.reduce((acc: any, msg: any) => {
    if (!acc[msg.conversationId]) {
      acc[msg.conversationId] = [msg]
    } else {
      acc[msg.conversationId].push(msg)
    }
    return acc
  }, {})
  const conversationsNotToday = messagesNotToday.reduce(
    (acc: any, msg: any) => {
      if (!acc[msg.conversationId]) {
        acc[msg.conversationId] = [msg]
      } else {
        acc[msg.conversationId].push(msg)
      }
      return acc
    },
    {}
  )

  const createNewChat = () => {
    setText('')
    setCurrentConversationId(Math.random().toString(36).substr(2, 9))
    inputRef.current?.focus()
  }

  // TODO when scrolling on one conversation, it also scrolls on the other conversation
  const handleConversationClick = (conversationId: any) => {
    setCurrentConversationId(conversationId)
    setText('')
    inputRef.current?.focus()
    setIsShowSidebar(false)
  }

  const submitHandlerStream = async (e: any) => {
    e.preventDefault()
    if (!text) return
    const newUserMessage = {
      conversationId: currentConversationId,
      role: 'user',
      content: [
        {
          type: 'text',
          text,
        },
      ],
      createdAt: new Date().toISOString(),
      userId: '1',
    }

    setIsResponseLoading(true)
    setErrorText('')

    const socket = new WebSocket(
      `${
        process.env.NODE_ENV === 'development'
          ? SERVER_URL.replace(/^http/, 'ws')
          : SERVER_URL.replace(/^https/, 'wss')
      }/ws`
    )
    setSocket(socket)
    socket.onopen = () => {
      socket.send(JSON.stringify({ message: newUserMessage }))
      setText('')
    }

    // only partially automatically scroll to the last item
    let maxScrollTokens = 150
    socket.onmessage = (event) => {
      const data = JSON.parse(event.data)
      if (data.error) {
        setErrorText(data.error.message)
        setText('')
      } else {
        if (maxScrollTokens > 0) {
          scrollToLastItem.current?.lastElementChild?.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
          })
          maxScrollTokens--
        }
        setErrorText('')
        setAllMessages((prevMessages: any) => {
          const existingMessageIndex = prevMessages.findIndex(
            (msg: any) => msg.messageId === data.message.message_id
          )

          const updatedMessages = [...prevMessages]

          // if the message doesn't exist, add it to the list
          if (existingMessageIndex === -1) {
            const formattedMessage = {
              messageId: data.message.message_id,
              conversationId: data.message.conversation_id,
              role: data.message.role,
              content: data.message.content,
              userId: '1',
            }
            return [...prevMessages, formattedMessage]
          } else {
            updatedMessages[existingMessageIndex].content[0].text +=
              data.message.content[0].text
            return updatedMessages
          }
        })
      }
    }

    socket.onerror = (error) => {
      console.error('WebSocket error:', error)
      setErrorText('Error sending message. Please try again later.')
      setIsResponseLoading(false)
    }

    socket.onclose = () => {
      socket.close()
      setIsResponseLoading(false)
      setSocket(null)
    }
  }

  // Deprecated. Use submitHandlerStream instead.
  const submitHandler = async (e: any) => {
    e.preventDefault()

    if (!text) return

    const newUserMessage = {
      conversationId: currentConversationId,
      role: 'user',
      content: [
        {
          type: 'text',
          text,
        },
      ],
      createdAt: new Date().toISOString(),
      userId: '1',
    }
    // temporarily add the message to the list. This will be replaced by the actual response since setAllMessages below uses an outdated allMessages state.
    setAllMessages([...allMessages, newUserMessage])

    scrollToLastItem.current?.lastElementChild?.scrollIntoView({
      behavior: 'smooth',
    })
    setIsResponseLoading(true)
    setErrorText('')

    try {
      const response = await fetch(`${SERVER_URL}/`, {
        ...fetchOptions,
        method: 'POST',
        body: JSON.stringify({
          message: newUserMessage,
        }),
      })

      if (response.status === 429) {
        return setErrorText('Too many requests, please try again later.')
      }

      const data = await response.json()

      if (data.error) {
        setErrorText(data.error.message)
        setText('')
      } else {
        setErrorText('')
      }

      if (!data.error) {
        setErrorText('')
        scrollToLastItem.current?.lastElementChild?.scrollIntoView({
          behavior: 'smooth',
        })
        setTimeout(() => {
          setText('')
        }, 2)

        // loop through messages. server returns the user's message plus the assistant's response
        const formattedMessages = []
        for (let i = 0; i < data.messages.length; i++) {
          const formattedMessage = {
            messageId: data.messages[i].message_id,
            conversationId: data.messages[i].conversation_id,
            role: data.messages[i].role,
            content: data.messages[i].content,
            userId: '1',
          }
          formattedMessages.push(formattedMessage)
        }

        setAllMessages([...allMessages, ...formattedMessages])
      }
    } catch (e: any) {
      setErrorText('Error sending message. Please try again later.')
      console.error(e)
    } finally {
      setIsResponseLoading(false)
    }
  }

  useLayoutEffect(() => {
    const handleResize = () => {
      setIsShowSidebar(window.innerWidth <= 640)
    }
    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  // hide sidebar when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const sidebar = document.querySelector('.sidebar') as Element
      if (sidebar && !sidebar.contains(event.target as Node)) {
        setIsShowSidebar(false)
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  // fetch previous messages
  useEffect(() => {
    async function getAllMessages() {
      try {
        const response = await fetch(`${SERVER_URL}/api/messages/`, {
          // ...fetchOptions,
          method: 'GET',
        })
        const data = await response.json()
        const formattedMessages = data.messages.map((msg: any) => {
          return {
            ...msg,
            conversationId: msg.conversation_id,
            messageId: msg.message_id,
            // createdAt: new Date(msg.created_at).toISOString(),
          }
        })
        setAllMessages(formattedMessages)
      } catch (error) {
        console.error('Error fetching previous messages:', error)
      }
    }

    getAllMessages()
  }, [])

  // for scrolling to the last item
  useEffect(() => {
    const chatContainer = document.querySelector('.main-body')
    function checkIsScrolledToBottom() {
      if (!chatContainer) return
      const { scrollHeight, scrollTop, clientHeight } = chatContainer
      const isAtBottom = scrollHeight - scrollTop <= clientHeight + 20 // add 20px buffer, or else scroll to bottom button will flicker
      setIsScrolledToBottom(isAtBottom)
    }
    if (chatContainer) {
      chatContainer.addEventListener('scroll', checkIsScrolledToBottom)
      checkIsScrolledToBottom()
    }
    return () => {
      if (chatContainer) {
        chatContainer.removeEventListener('scroll', checkIsScrolledToBottom)
      }
    }
  }, [currentConversationId])
  return (
    <>
      <div className={`container ${isPopupOpen ? '' : 'closed'}`}>
        <section className="main">
          {currentMessages.length === 0 && (
            <div className="empty-chat-container">
              <img
                src={brainPng}
                style={{ borderRadius: '50%' }}
                width={45}
                height={45}
                alt="ChatGPT"
              />
              <h1>Music Store Assistant</h1>
              <h5>
                Say 'Get my orders' (Hint: Use customer ID 37077)
                <br />
                Say 'Get all orders'
                <br />
                Say 'What are the top 5 highly-rated guitar products?'
                <br />
                Say 'Is the BOYA BYM1 Microphone good for a cello?'
                <br />
              </h5>
            </div>
          )}
          <div className="main-body">
            <ul className="App_current_messages_ul">
              {currentMessages?.map((chatMsg: any) => {
                const isUser = chatMsg.role === 'user'
                return (
                  <li
                    className="App_message_li"
                    key={chatMsg.messageId}
                    ref={scrollToLastItem}
                  >
                    {isUser ? (
                      <div>
                        <BiSolidUserCircle size={28.8} />
                      </div>
                    ) : (
                      <img
                        className="avatar"
                        src={brainPng}
                        alt="ChatGPT"
                        style={{ borderRadius: '50%' }}
                      />
                    )}
                    {isUser ? (
                      <div>
                        <p className="role-title">You</p>
                        <p>{chatMsg.content[0].text}</p>
                      </div>
                    ) : (
                      <div>
                        <p className="role-title">Store Assistant</p>
                        <p>
                          {chatMsg.content[0]?.text
                            ?.split('\n')
                            .map((line: any, index: any) => (
                              <React.Fragment key={index}>
                                {line}
                                <br />
                              </React.Fragment>
                            ))}
                        </p>
                      </div>
                    )}
                  </li>
                )
              })}
            </ul>
          </div>
          {!isScrolledToBottom && (
            <BiDownArrow
              size={25}
              className="App_scroll-to-bottom"
              onClick={scrollToBottom}
            />
          )}
          {errorText && <p className="errorText">{errorText}</p>}
          <div className="main-bottom">
            <form className="form-container" onSubmit={submitHandlerStream}>
              <input
                className="App_send_message"
                ref={inputRef}
                type="text"
                placeholder="Send a message."
                spellCheck="false"
                value={isResponseLoading ? 'Processing...' : text}
                onChange={(e) => setText(e.target.value)}
                readOnly={isResponseLoading}
              />
              {isResponseLoading ? (
                <button
                  type="button"
                  onClick={() => {
                    // TODO need to send a close message so server can stop processing
                    socketState?.close()
                    setSocket(null)
                  }}
                >
                  Cancel
                </button>
              ) : (
                <button type="submit">
                  <BiSend size={20} />
                </button>
              )}
              {isConnecting ? (
                <div>Connecting...</div>
              ) : (
                <button
                  onClick={isSessionActive ? stopSession : startSession}
                  type="button"
                  style={{
                    backgroundColor: isSessionActive ? 'red' : '',
                    borderRadius: '100%',
                    padding: '0.5rem',
                  }}
                >
                  <BiMicrophone size={20} />
                </button>
              )}
            </form>
          </div>
        </section>{' '}
        <section className={`sidebar ${isShowSidebar ? '' : 'closed'}`}>
          <div
            className="sidebar-header"
            onClick={() => {
              if (isSessionActive || isConnecting) {
                alert('End voice before switching conversations')
              } else {
                createNewChat()
                setIsShowSidebar(false)
              }
            }}
            role="button"
          >
            <BiPlus size={20} />
            <button className="App_new_chat">New Chat</button>
          </div>
          {/* not clickable when microphone is on, because if user says something, then click on a new conversation right away, realtime's response is going to go to the new conversation */}
          <div className="sidebar-history">
            <ConversationsList
              conversations={conversationsToday}
              title="Today"
              handleConversationClick={handleConversationClick}
            />
            <ConversationsList
              conversations={conversationsNotToday}
              title="Previous"
              handleConversationClick={handleConversationClick}
            />
          </div>
          <div className="sidebar-info">
            <div>
              <a
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  width: 'auto',
                }}
                href={
                  process.env.NODE_ENV === 'production'
                    ? 'tel:707-682-7759'
                    : '279-205-7759'
                }
              >
                <BiPhone size={20} />
                <p>
                  Call{' '}
                  {process.env.NODE_ENV === 'production'
                    ? '(707) 682-7759'
                    : '(279) 205-7759'}
                </p>
              </a>
            </div>
            {/* <div className="sidebar-info-upgrade">
              <BiUser size={20} />
              <p>Upgrade plan</p>
            </div>
            <div className="sidebar-info-user">
              <BiSolidUserCircle size={20} />
              <p>User</p>
            </div> */}
          </div>

          <div
            onClick={() => setIsShowSidebar((prev: boolean) => !prev)}
            className={`burger ${isShowSidebar ? 'left' : 'right'}`}
          >
            <MdOutlineArrowRight size={32} />
          </div>
        </section>
        <div
          className="App_close_popup"
          onClick={() => {
            setIsPopupOpen(false)
          }}
        >
          <MdMinimize size={20} />
        </div>
      </div>

      <img
        className={`App_open_popup ${isPopupOpen && 'closed'}`}
        src={brainPng}
        alt="OpenAI"
        onClick={() => setIsPopupOpen(true)}
      />
    </>
  )
}

export default App
