import { useEffect, useRef, useState, useContext } from 'react'
import { ConversationContext } from './ConversationContext'
import { SERVER_URL } from './constants'
import { fetchOptions } from './fetchOptions'
import { scrollToBottom } from './utils'
// Didn't manage to import a local mp3 in prod, so just fetched it from backend
import useAudio from './useAudio'

export default function useTools({ sendClientEvent, events }: any) {
  const { currentConversationId, setAllMessages }: any =
    useContext(ConversationContext)
  const { playTypingAudio, stopTypingAudio } = useAudio()

  useEffect(() => {
    if (!events || events.length === 0) return

    const mostRecentEvent = events[0]
    // console.log(`*Example mostRecentEvent: `, mostRecentEvent)

    // initialize the session's configurations. Initializing in the server doesn't set the input_audio_transcript
    if (events.length < 3) {
      sendClientEvent({
        type: 'session.update',
        session: {
          input_audio_transcription: {
            model: 'whisper-1',
          },
        },
      })
    }

    // TODO if I want the transcript to stream, watch for type deltas. I would have to know the itemId in the front-end to know which message to update based on its item_id.

    // don't need to do anything if the event is not a function call or function call output because it is already handled during execute tools
    if (
      mostRecentEvent.item?.type === 'function_call' ||
      mostRecentEvent.item?.type === 'function_call_output'
    ) {
      return
    }

    // when the user or the assistant says something, it starts with conversation.item.created. Save the placeholder message to the server
    // update user's or assistant's transcript to the server so it can be displayed in the chat as text. Sometimes it picks up on empty messages, so check if there's a transcript and do nothing if it's empty. Tool calls also start with conversation.item.created
    if (
      mostRecentEvent.type ===
        'conversation.item.input_audio_transcription.completed' || // user
      (mostRecentEvent.type === 'response.audio_transcript.done' && // assistant
        mostRecentEvent.transcript) ||
      mostRecentEvent.type === 'conversation.item.created'
    ) {
      let role
      if (
        mostRecentEvent.type ===
        'conversation.item.input_audio_transcription.completed'
      ) {
        role = 'user'
      } else if (mostRecentEvent.type === 'response.audio_transcript.done') {
        role = 'assistant'
      } else if (mostRecentEvent.type === 'conversation.item.created') {
        role = mostRecentEvent.item.role // could be either from the user or the assistant
      }
      let itemId
      let contentText
      if (mostRecentEvent.type === 'conversation.item.created') {
        itemId = mostRecentEvent.item.id
        // it doesn't have a transcript when it is first created
      } else {
        itemId = mostRecentEvent.item_id
        contentText = mostRecentEvent.transcript
      }
      ;(async () => {
        try {
          const response = await fetch(`${SERVER_URL}/save_message`, {
            ...fetchOptions,
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              conversationId: currentConversationId,
              role,
              content: [{ type: 'text', text: contentText || '' }],
              itemId,
            }),
          })
          const data = await response.json()
          // update the messages that were updated on the server
          setAllMessages((prevMessages: any) => {
            const existingMessageIndex = prevMessages.findIndex(
              (msg: any) => msg.messageId === data.message.message_id
            )

            const updatedMessages = [...prevMessages]

            // if the message doesn't exist, add it to the list
            if (existingMessageIndex === -1) {
              const formattedMessage = {
                messageId: data.message.message_id,
                conversationId: data.message.conversation_id,
                role: data.message.role,
                content: data.message.content,
                userId: '1',
              }
              return [...prevMessages, formattedMessage]
            } else {
              updatedMessages[existingMessageIndex].content[0].text +=
                data.message.content[0].text
              return updatedMessages
            }
          })
          setTimeout(() => {
            scrollToBottom()
          }, 0)
        } catch (error) {
          console.error('Error:', error)
        }
      })()
    }

    // If realtime decides to do multiple tool calls, it will be in separate response.done events. But some how it will have one response for all the tool calls.
    if (mostRecentEvent.type === 'response.function_call_arguments.done') {
      // functionCalls might always be just one, since realtime streams function calls one at a time

      // let the user know that the server is processing
      sendClientEvent({
        type: 'response.create',
        response: {
          // input is an alternative to instructions. instructions don't work here. It causes redundant tool calls.
          input: [
            {
              type: 'message',
              role: 'user',
              content: [
                {
                  type: 'input_text',
                  text: `You talk very very fast. Say a variation of "Processing!" or "I'm on it!"`,
                },
              ],
            },
          ],
          temperature: 1.2, // anything below 0.6 it doesn't respond. 1.2 is the highest.
        },
      })
      playTypingAudio()

      mostRecentEvent.conversationId = currentConversationId

      fetch(`${SERVER_URL}/tools`, {
        ...fetchOptions,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(mostRecentEvent),
      })
        .then((response) => response.json())
        .then((data) => {
          const output = data.messages[0].content[0].text
          const functionCallOutput = {
            type: 'conversation.item.create',
            item: {
              type: 'function_call_output',
              call_id: mostRecentEvent.call_id,
              output,
            },
          }
          sendClientEvent(functionCallOutput)
          setTimeout(() => {
            sendClientEvent({
              type: 'response.create',
            })
            stopTypingAudio()
          }, 3000) // so the 'I'm on it!' message doesn't clash with this
        })
    }
  }, [events])

  return {}
}
