import React, { useContext } from 'react'
import { ConversationContext } from './ConversationContext'

function ConversationsList({
  conversations,
  title,
  handleConversationClick,
}: any) {
  const { currentConversationId, isSessionActive, isConnecting }: any =
    useContext(ConversationContext)
  return (
    <>
      {Object.keys(conversations).length > 0 && (
        <>
          <p>{title}</p>
          <ul>
            {Object.entries(conversations)?.map(
              ([conversationId, messages]: any) => {
                // find the first user message
                const firstUserMessage = messages.find(
                  (m: any) => m.role === 'user'
                )
                return (
                  <li
                    key={conversationId}
                    onClick={() =>
                      isSessionActive || isConnecting
                        ? alert('End voice before switching conversations')
                        : handleConversationClick(conversationId)
                    }
                    className={`Conversation_Unique_Title ${
                      conversationId === currentConversationId ? 'active' : ''
                    }`}
                  >
                    {firstUserMessage?.content[0].text}
                  </li>
                )
              }
            )}
          </ul>
        </>
      )}
    </>
  )
}

export default ConversationsList
